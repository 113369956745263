<template>
  <div class="row">
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Penultima Menstruación </label>
      <input type="date" class="form-control form-control-sm" v-model="embarazo_actual.penultima_menstruacion" >
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Ultima Menstruación <span class="text-danger">*</span></label>
      <input type="date" class="form-control form-control-sm" v-model="embarazo_actual.ultima_menstruacion" :class="{'is-invalid':$v.embarazo_actual.ultima_menstruacion.$error }">
      <small class="text-danger" v-if="!$v.embarazo_actual.ultima_menstruacion.validDateFormat">Fecha invalida</small>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Semanas de gestación <span class="text-danger">*</span></label>
      <input type="number" class="form-control form-control-sm" v-model="embarazo_actual.semanas_gestacion" :class="{'is-invalid':$v.embarazo_actual.semanas_gestacion.$error && $v.embarazo_actual.semanas_gestacion.$dirty}">
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Fecha probable de parto <span class="text-danger">*</span></label>
      <input type="date" class="form-control form-control-sm"  v-model="embarazo_actual.fecha_parto" :class="{'is-invalid':$v.embarazo_actual.fecha_parto.$error}">
      <small class="text-danger" v-if="!$v.embarazo_actual.fecha_parto.validDateFormat">Fecha invalida</small>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Presencia de movimientos fetales <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm" 
      v-model="embarazo_actual.movimientos_fetales" 
      :class="{'is-invalid':$v.embarazo_actual.movimientos_fetales.$error && $v.embarazo_actual.movimientos_fetales.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Sintomas urinarios <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm"
      v-model="embarazo_actual.sintomas_urinarios" 
      :class="{'is-invalid':$v.embarazo_actual.sintomas_urinarios.$error && $v.embarazo_actual.sintomas_urinarios.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Sintomas cervico vaginales <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm"
      v-model="embarazo_actual.cervico_vaginales" 
      :class="{'is-invalid':$v.embarazo_actual.cervico_vaginales.$error && $v.embarazo_actual.cervico_vaginales.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Cefalea persistente <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm"
      v-model="embarazo_actual.cefalea_persistente" 
      :class="{'is-invalid':$v.embarazo_actual.cefalea_persistente.$error && $v.embarazo_actual.cefalea_persistente.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Edema en cara o miembros <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm"
      v-model="embarazo_actual.edema" 
      :class="{'is-invalid':$v.embarazo_actual.edema.$error && $v.embarazo_actual.edema.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Epigastralgia <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm"
      v-model="embarazo_actual.epigastralgia" 
      :class="{'is-invalid':$v.embarazo_actual.epigastralgia.$error && $v.embarazo_actual.epigastralgia.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
    <div class="form-group col-lg-4 col-md-12 col-sm-12">
      <label class="form-label">Nauseas y vomito <span class="text-danger">*</span></label>
      <select class="form-control form-control-sm"
      v-model="embarazo_actual.nauseas" 
      :class="{'is-invalid':$v.embarazo_actual.nauseas.$error && $v.embarazo_actual.nauseas.$dirty}">
        <option value="">--Seleccione--</option>
        <option value="SI">SI</option>
        <option value="NO">NO</option>
      </select>
    </div>
  </div>
</template>
<script>
import embarazoActualService from "../../../../services/embarazoActualService";
import Toast from '../../../../components/common/utilities/toast';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from "lodash";
import embarazoService from '../../../../services/embarazoService';
import {dateFormat} from "../../../../utils/validators/index";
import dayjs from "dayjs";

export default {
  props: ['idHistoria','finalizada','idUsuario'],
  data(){
    return {
      id_historia:'',
      embarazo_actual:{
        id:'',
        id_historia:'',
        id_embarazo:'',
        ultima_menstruacion:'',
        semanas_gestacion:'',
        fecha_parto:'',
        movimientos_fetales:'',
        sintomas_urinarios:'',
        cervico_vaginales:'',
        cefalea_persistente:'',
        edema:'',
        epigastralgia:'', 
        nauseas:'',
        penultima_menstruacion:''
      },
      embarazo:{}
    }
  },
  validations (){
    return {
      embarazo_actual: {
        ultima_menstruacion:{
          validDateFormat:(value) => dateFormat(value),
          required
        },
        semanas_gestacion:{required},
        fecha_parto:{
          validDateFormat:(value) => dateFormat(value),
          required
        },
        movimientos_fetales:{required},
        sintomas_urinarios:{required},
        cervico_vaginales:{required},
        cefalea_persistente:{required},
        edema:{required},
        epigastralgia:{required}, 
        nauseas:{required}
      }
    }
  },
  methods: {
    async save(){
      try {
        this.$v.embarazo_actual.$touch();
        if(this.$v.embarazo_actual.$invalid) return;
        this.embarazo_actual.id_historia=this.idHistoria;
        this.embarazo_actual.id_embarazo= !isEmpty(this.embarazo) && this.embarazo.activo === 'S'?this.embarazo.id:null;
        this.LoaderSpinnerShow();
        if(this.id_historia!=='' && parseInt(this.id_historia)===parseInt(this.idHistoria)){
          await embarazoActualService.update(this.embarazo_actual);
        }else{
          await embarazoActualService.store(this.embarazo_actual);
        }

        this.LoaderSpinnerHide();
        Toast.fire({
            icon: 'success',
            title: 'Datos guardados con exito'
        });
      } catch (error) {
        console.error(error);
        this.LoaderSpinnerHide();
        Toast.fire({
            icon: 'error',
            title: 'Ocurrio un error al procesar la solicitud'
        });
            
      }
      
    },
  },
  watch:{
    finalizada: async function (value){
      if(value==='N'){
        const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
        this.embarazo=res_embarazo_actual.data;
        if(!isEmpty(res_embarazo_actual.data)){
          const response=await embarazoActualService.showByLast(this.embarazo.id);
          if(!isEmpty(response.data)){
            this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
            this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
            this.embarazo_actual.fecha_parto=response.data.fecha_parto;
            this.embarazo_actual.id_historia=response.data.id_historia;
            this.embarazo_actual.id_embarazo=response.data.id_embarazo;
            this.embarazo_actual.id=response.data.id;
            this.id_historia=response.data.id_historia;
            this.embarazo_actual.movimientos_fetales=response.data.movimientos_fetales;
            this.embarazo_actual.sintomas_urinarios=response.data.sintomas_urinarios;
            this.embarazo_actual.cervico_vaginales=response.data.cervico_vaginales;
            this.embarazo_actual.cefalea_persistente=response.data.cefalea_persistente;
            this.embarazo_actual.edema=response.data.edema;
            this.embarazo_actual.epigastralgia=response.data.epigastralgia;
            this.embarazo_actual.nauseas=response.data.nauseas;
            this.embarazo_actual.penultima_menstruacion=response.data.penultima_menstruacion;
          }
        }
      }
    },
    'embarazo_actual.ultima_menstruacion': function() {
      this.embarazo_actual.fecha_parto = this.dateParto;
      this.embarazo_actual.semanas_gestacion=this.semanasGestacion;
    }
  },
  async created(){
    this.embarazo_actual.fecha_parto = this.dateParto;
    if(this.finalizada==='N'){

      const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
      this.embarazo=res_embarazo_actual.data;

      if(!isEmpty(res_embarazo_actual.data)){
        const response=await embarazoActualService.showByLast(this.embarazo.id);
        if(!isEmpty(response.data)){
          this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
          this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
          this.embarazo_actual.fecha_parto=response.data.fecha_parto;
          this.embarazo_actual.id_historia=response.data.id_historia;
          this.embarazo_actual.id_embarazo=response.data.id_embarazo;
          this.embarazo_actual.id=response.data.id;
          this.id_historia=response.data.id_historia;
          this.embarazo_actual.movimientos_fetales=response.data.movimientos_fetales;
          this.embarazo_actual.sintomas_urinarios=response.data.sintomas_urinarios;
          this.embarazo_actual.cervico_vaginales=response.data.cervico_vaginales;
          this.embarazo_actual.cefalea_persistente=response.data.cefalea_persistente;
          this.embarazo_actual.edema=response.data.edema;
          this.embarazo_actual.epigastralgia=response.data.epigastralgia;
          this.embarazo_actual.nauseas=response.data.nauseas;
          this.embarazo_actual.penultima_menstruacion=response.data.penultima_menstruacion;

        }
      }

    }
  },
  computed:{
    dateParto(){
      const fechaUltimaMenstruacion = dayjs(this.embarazo_actual.ultima_menstruacion);
      const fechaProbableParto = fechaUltimaMenstruacion.add(280, 'day');
      return  fechaProbableParto.format('YYYY-MM-DD');
    },
    semanasGestacion(){
      /* const fechaUltimaMenstruacion = dayjs(this.embarazo_actual.ultima_menstruacion);
      const fechaActual = dayjs();
      const semanasGestacion = fechaActual.diff(fechaUltimaMenstruacion, 'week');
      return Math.max(semanasGestacion, 0); */
      const fechaUltimaMenstruacion = dayjs(this.embarazo_actual.ultima_menstruacion);
      const fechaActual = dayjs();
      const diasGestacion = fechaActual.diff(fechaUltimaMenstruacion, 'day');
      const semanasGestacion = diasGestacion / 7;
      const semanasGestacionConDecimales = parseFloat(semanasGestacion.toFixed(1));
      return Math.max(semanasGestacionConDecimales, 0);

    }
  }
}
</script>