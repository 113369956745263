<template>
    <div class="radio-inputs">
		<label
            @click="selectOption(i)" 
            v-for="(trim, i) in trimestres" 
            :data-trim="`trim_${trim.id}`" 
            :key="`${i}_trim`">
			<input class="radio-input" type="radio" name="engine" :value="trim.value" v-model="trimestre">
				<span class="radio-tile">
					<span class="radio-icon">
						<i :class="trim.icon"></i>
					</span>
					<span class="radio-label">{{trim.name}}</span>
				</span>
		</label>
    </div>
</template>
<script>
export default {
    props:['label','value','trimestres'],
    data() {
        return {
            trimestreSeleccionado: '',
            trimestre : '',
        }
    },
    methods: {
        selectOption(i) {
            const trim = this.trimestres[i];
            this.trimestreSeleccionado = trim;
        },
    },
    watch: {
        value: async function (value) {
           
            if (value === "" || value === undefined || value === null) {
                this.trimestre= "";
                return;
            }
            
            this.trimestre = value;
        },
        trimestre : function (value) {
            this.$emit('input', value);
        }, 
    },
    created() {
        this.trimestre = this.value;
    },
}
</script>
<style scoped>
.radio-inputs {
    
    align-items: center;
    max-width: 350px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .radio-inputs > * {
    margin: 6px;
  }
  
  .radio-input:checked + .radio-tile {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    color: #2260ff;
  }
  
  .radio-input:checked + .radio-tile:before {
    transform: scale(1);
    opacity: 1;
    background-color: #2260ff;
    border-color: #2260ff;
  }
  
  .radio-input:checked + .radio-tile .radio-icon svg {
    fill: #2260ff;
  }
  
  .radio-input:checked + .radio-tile .radio-label {
    color: #2260ff;
  }
  
  .radio-input:focus + .radio-tile {
    border-color: #2260ff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1), 0 0 0 4px #b5c9fc;
  }
  
  .radio-input:focus + .radio-tile:before {
    transform: scale(1);
    opacity: 1;
  }
  
  .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;
    min-height: 80px;
    border-radius: 0.5rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    transition: 0.15s ease;
    cursor: pointer;
    position: relative;
  }
  
  .radio-tile:before {
    content: "";
    position: absolute;
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    border: 2px solid #b5bfd9;
    background-color: #fff;
    border-radius: 50%;
    top: 0.25rem;
    left: 0.25rem;
    opacity: 0;
    transform: scale(0);
    transition: 0.25s ease;
  }
  
  .radio-tile:hover {
    border-color: #2260ff;
  }
  
  .radio-tile:hover:before {
    transform: scale(1);
    opacity: 1;
  }
  
  .radio-icon svg {
    width: 2rem;
    height: 2rem;
    fill: #494949;
  }
  
  .radio-label {
    color: #707070;
    transition: 0.375s ease;
    text-align: center;
    font-size: 13px;
  }
  
  .radio-input {
    clip: rect(0 0 0 0);
    -webkit-clip-path: inset(100%);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
</style>