<template>
    <main>
        <div class="">
            <div class="card-header bg-light "><span><i class="fa fa-diagnoses"> </i> Examen Fisico Embarazo</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <h6> Altura Uterina : {{ examen_fisico_embarazo.altura_uterina }} </h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6> FCF Feto 1: {{ examen_fisico_embarazo.fcf }} </h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6> Presentación feto 1: {{ examen_fisico_embarazo.presentacion }} </h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6> FCF Feto 2: {{ examen_fisico_embarazo.fcf_2 }} </h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6> Presentación feto 2: {{ examen_fisico_embarazo.presentacion_2 }} </h6>
                    </div>
                    <div class="form-group col-md-4">
                        <h6> Movimientos Fetales : {{ examen_fisico_embarazo.movimiento_fetal }} </h6>
                    </div>
                </div>
            </div>
        </div>

    </main>
</template>
<script>
import examenFisicoEmbarazoService from "../../../../services/examenFisicoEmbarazoService";
import { isEmpty } from 'lodash';
export default {
    props: ['idHistoria', 'finalizada'],
    data() {
        return {
            examen_fisico_embarazo: {
                altura_uterina: '',
                fcf: '',
                fcf_2: '',
                movimiento_fetal: '',
                presentacion: '',
                presentacion_2: '',
                id_examen_fisico_embarazo: ''
            }
        }
    },
    async created() {

        const response = await examenFisicoEmbarazoService.showByHc(this.idHistoria);
        if (!isEmpty(response.data)) {
            this.examen_fisico_embarazo.fcf = response.data.fcf;
            this.examen_fisico_embarazo.fcf_2 = response.data.fcf_2;
            this.examen_fisico_embarazo.altura_uterina = response.data.altura_uterina;
            this.examen_fisico_embarazo.movimiento_fetal = response.data.movimiento_fetal;
            this.examen_fisico_embarazo.presentacion = response.data.presentacion;
            this.examen_fisico_embarazo.presentacion_2 = response.data.presentacion_2;
            this.examen_fisico_embarazo.id_examen_fisico_embarazo = response.data.id;

        }

    }
}
</script>