<template>
  <main>
    <div class="row">
      <div
        class="col-md-10 offset-md-1 col-lg-10 offset-lg-1 col-sm-12 col-xs-12 mb-1"
        v-for="(chart, i) in apply"
        :key="`chart_${i}`"
      >
        <component
          :idUsuario="usuario.id"
          :ref="chart.component"
          :is="chart.component"
          v-bind="chartsData[chart.component]"
        ></component>
      </div>
    </div>
  </main>
</template>
<script>
import uterineHeightGraph from "../base/baseGraficasEmbarazo/uterineHeightGraph";
import presionArterialGraph from "../base/baseGraficasEmbarazo/presionArterialGraph";
import imcGraph from "../base/baseGraficasEmbarazo/imcGraph";
import dayjs from "dayjs";
//import {isEmpty} from "lodash";
import graficasService from "@/services/graficasService";
import embarazoService from "../../../services/embarazoService";

export default {
  props: ["usuario", "idHistoria"],
  components: { uterineHeightGraph, presionArterialGraph, imcGraph },
  data() {
    return {
      charts: [
        {
          name: "ALTURA UTERINA",
          component: "uterineHeightGraph",
        },
        {
          name: "PRESION ARTERIAL",
          component: "presionArterialGraph",
        },
        {
          name: "IMC",
          component: "imcGraph",
          start: 2,
          end: 5,
          gender: "F",
        },
      ],
      data_altura_uterina: {},
      data_presion_arterial: {},
      data_imc: {},
    };
  },
  async created() {
    this.LoaderSpinnerShow();
    this.cargarAlturauterina();
    this.cargarPresionArterial();
    this.cargarPesoTalla();
    this.LoaderSpinnerHide();
  },
  methods: {
    async save() {
      let charts = [];

      this.apply.forEach((x) => {
        charts.push(this.$refs[x.component][0].export());
      });

      await graficasService.store({
        id_historia: this.idHistoria,
        charts: charts,
      });

     /*  for (let index = 0; index < charts.length; index++) {
        await graficasService.store({
          id_historia: this.idHistoria,
          charts: charts[index],
        });
      } */
    },
    async cargarAlturauterina() {
      const response = await embarazoService.showAlturaUterina(this.usuario.id);
      this.data_altura_uterina = response.data;
    },
    async cargarPresionArterial() {
      const response = await embarazoService.showPresionArterial(
        this.usuario.id
      );
      this.data_presion_arterial = response.data;
    },
    async cargarPesoTalla() {
      const response = await embarazoService.showPesoTalla(this.usuario.id);
      this.data_imc = response.data;
    },
  },
  computed: {
    edad: function () {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "years");
    },
    edadEnMeses: function () {
      const now = dayjs();
      return now.diff(this.usuario.fecha_nacimiento, "month", true);
    },
    apply() {
      return this.charts;
    },
    chartsData() {
      return {
        uterineHeightGraph: {
          data_chart: this.data_altura_uterina,
          label: "Altura Uterina",
        },
        presionArterialGraph: {
          data_chart: this.data_presion_arterial,
          label: "PRESION ARTERIAL",
        },
        imcGraph: {
          data_imc: this.data_imc,
          name: "IMC",
          x: 20,
          y: 23,
        },
      };
    },
  },
};
</script>
