<template>

    <main ref="resumen" id="resumen">
        <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
        <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
        <div class="py-2"><examenFisicoEmbarazoShow :idHistoria="idHistoria"></examenFisicoEmbarazoShow></div>
        <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
        <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
        <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
        <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
        <div class="py-2"><riesgoBiopsicosocialShow :idHistoria="idHistoria"></riesgoBiopsicosocialShow></div>
        <div class="py-2"><showExpViolencia :idHistoria="idHistoria" :codigo="'EXP_VIOL'"></showExpViolencia></div>
        <div class="py-2"><showRiesgoDepresion :idHistoria="idHistoria" :codigo="'R_DEP'"></showRiesgoDepresion></div>
        <div class="py-2"><vacunasShow :idHistoria="idHistoria" :idUsuario="idUsuario"></vacunasShow></div>
        <div class="py-2"><examenesShow :idHistoria="idHistoria" :idUsuario="idUsuario"></examenesShow></div>
        <div class="py-2"><embarazoActualShow :idHistoria="idHistoria" :idUsuario="idUsuario"></embarazoActualShow></div>
       
    </main>

</template>
<script>
import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import riesgoBiopsicosocialShow from "../../base/baseRiesgobiopsicosocial/index/riesgoBiopsicosocialShow";
import vacunasShow from "../../base/baseVacunas/index/vacunasShow";
import examenesShow from "../../base/baseReporteExamenes/index/examenesShow";
import embarazoActualShow from "../../base/baseEmbarazoActual/embarazoActualShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import examenFisicoEmbarazoShow from "../../base/examenFisico/examenFisicoEmbarazoShow";
import showExpViolencia from "../../base/baseRespuestaFormularios/showRespuestas";
import showRiesgoDepresion from "../../base/baseRespuestaFormularios/showRespuestas";


export default {
    props: ['idHistoria','idUsuario'],
    components:{motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    riesgoBiopsicosocialShow,
    vacunasShow,
    examenesShow,
    embarazoActualShow,exploracionFisicaShow,examenFisicoEmbarazoShow,showExpViolencia,showRiesgoDepresion},
}
</script>
<style scoped>
.resumen-container {
    height:300px;
    overflow-y:scroll
}

</style>