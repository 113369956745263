<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Riesgo Bipsicosocial</span> </div>
            <div class="card-body">
            <div v-for="item in preguntas" :key="item.id">
                <h6>{{item.nombre}}</h6>
                    <table class="table table-striped table-bordered table-sm" >
                    <tr>
                        <th>Pregunta</th>
                        <th>Respuesta</th>
                    </tr>
                    <tr v-for="pre in item.pregunta" :key="pre.id">
                        <td>{{pre.texto}}</td>
                        <td>
                        {{pre.respuesta}}
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row mt-2">
                <div class="form-group col-12">
                    <label>Puntaje Total: </label>
                    <span>{{puntaje}}</span>
                </div>
            </div>
            </div>
        </div>
       
    </main>
</template>
<script>
import usuarioService from '../../../../../services/usuarioService';
import tipoRiesgoBiopsicosocialService from '../../../../../services/tipoRiesgoBiopsicosocialService';
import valoracionRiesgoBiopsicosocialService from "../../../../../services/valoracionRiesgoBiopsicosocialService";
import {isEmpty} from "lodash";

export default {
    props: ['idHistoria','finalizada','idUsuario'],
    data() {
        return {
            usuario: {},
            preguntas: [],
            id_valoracion:''
        };
    },
    methods: {
        async cargarUsuario(id) {
            const response = await usuarioService.show(id);
            this.usuario = response.data;
        },
        async cargarPreguntas(){

            const res_valoracion = await valoracionRiesgoBiopsicosocialService.showByHc({
                id_historia: this.idHistoria
            });
            if(!isEmpty(res_valoracion.data)) {
                this.id_valoracion=res_valoracion.data.id;
            }

            const response = await tipoRiesgoBiopsicosocialService.index();
            this.preguntas = response.data.map(p => {
               
                return {
                    ...p,
                    pregunta : p.pregunta.map(x => {
                         let respuesta='';
                        if(!isEmpty(res_valoracion.data)) {
                            respuesta=res_valoracion.data.valoracion_detalles.find(res=>parseInt(res.id_pregunta)===parseInt(x.id)).valor
                        }
                            return {
                                ...x,
                                respuesta : respuesta
                            }
                        
                    })
                }
            });
        },
      
    },
    async created() {
        await this.cargarUsuario(this.idUsuario);
        this.cargarPreguntas();
    },
    computed : {
        puntaje : function(){
            
            let total  = 0
            
            this.preguntas.filter(x => x.grupo === 'NO').forEach(seccion => {
                total += seccion.pregunta.reduce((t,c) => {
                   if(c.respuesta === '') return Number(t);
                   return Number(t) + Number(c.respuesta);
                },0)
            });


            this.preguntas.filter(x => x.grupo === 'SI').forEach(seccion => {
                
                
                let grupos = [];

                seccion.pregunta.forEach(c => {
                    if(!grupos.includes(c.grupo)) grupos.push(c.grupo);
                }); 
                
                grupos.forEach(g => {

                    let mayor_valor = '';

                    seccion.pregunta.filter(p => p.grupo === g).forEach(pregunta => {
                        mayor_valor = Math.max(...pregunta.opciones.map(op => Number(op.valor)));
                    });

                    total += seccion.pregunta.filter(x => {
                        return (Number(x.respuesta) === Number(mayor_valor)) && x.grupo === g
                    }).length >= 2
                    ? 1
                    : 0;
                });


            });

            return total;

        },
        clasificacion(){

            const clasifications = {
                'bajo' : {
                    code : 'bajo',
                    text  : 'Bajo riesgo biopsicosocial',
                    class : 'badge badge-success'
                },
                'alto' : {
                    code : 'alto',
                    text  : 'Alto riesgo biopsicosocial',
                    class : 'badge badge-danger'
                }
            };

            return this.puntaje > 3 ? clasifications['alto'] : clasifications['bajo'];

        }
    }
}
</script>