<template>
  <main>
    <div v-if="trimestreAvaliable.length>0" class="form-group">
      <label class="form-label small text-muted">Seleccione trimestre a evaluar</label>
      <radioButtonComponent :label="'Trimestres'" 
        v-model="trimestre"
        :trimestres="select_trimestres"
        >
      </radioButtonComponent>
      <!-- <select class="form-control form-control-sm" v-model="trimestre" >
        <option :value="item.value" v-for="(item,i) in trimestreAvaliable" :key="i">{{item.label}}</option>
      </select> -->
    </div>
    <div class="alert alert-info" v-else>
      <i class="fa fa-exclamation fa-fw"></i>No hay trimestres por evaluar
    </div>

    <div class="card mt-2 shadow-none" v-if="trimestre!==''">
      <div class="card-header">Trimestre: {{trimestre}}</div>
      <div class="card-body">
        <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Riesgo Biopsicosocial</a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-exp-tab" data-toggle="pill" href="#pills-exp" role="tab" aria-controls="pills-exp" aria-selected="false">Exposición a violencia </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-riesgo-tab" data-toggle="pill" href="#pills-riesgo" role="tab" aria-controls="pills-riesgo" aria-selected="false">Riesgo de Depresión </a>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <index ref="escala" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :trimestre="trimestre"></index>
          </div>
          <div class="tab-pane fade" id="pills-exp" role="tabpanel" aria-labelledby="pills-contact-tab">
            <expViolencia ref="expViolencia" :codigo="'EXP_VIOL'" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :trimestre="trimestre"></expViolencia>
            
          </div>
          <div class="tab-pane fade" id="pills-riesgo" role="tabpanel" aria-labelledby="pills-riesgo-tab">
            <RiesgoDepresion ref="riesgoDepresion" :codigo="'R_DEP'" :idHistoria="idHistoria" :finalizada="finalizada" :idUsuario="idUsuario" :trimestre="trimestre"></RiesgoDepresion>
            
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import index from "../base/baseRiesgobiopsicosocial/index/index";
//import indexExamenes from "../base/baseReporteExamenes/index/indexExamenes";
import expViolencia from "../base/baseRespuestaFormularios/index";
import RiesgoDepresion from "../base/baseRespuestaFormularios/index";
import formulariosTrimestreService from "../../../services/formulariosTrimestreService";
import radioButtonComponent from "../../../components/common/radioButtons/radioButtonComponent";
import {isEmpty} from "lodash";
export default {
  props: ['idHistoria','finalizada','idUsuario','idTipoHistoria'],
  components:{index,expViolencia,RiesgoDepresion,radioButtonComponent},
  data(){
    return {
      trimestre:'',
      usuario_trimestre:'',
      select_trimestres:[
        {
          id:1,
          value:1,
          name:'Trimestre 1',
          icon:"fas fa-dice-one fa-2x"
        },
        {
          id:2,
          value:2,
          name:'Trimestre 2',
          icon:"fas fa-dice-two fa-2x"
        },
        {
          id:3,
          value:3,
          name:'Trimestre 3',
          icon:"fas fa-dice-three fa-2x"
        }
      ]
    }
  },
  methods : {
    async save(){
      if(!isEmpty(this.$refs.escala)){
        await this.$refs.escala.save();
      }
      if(!isEmpty(this.$refs.expViolencia)){
        await this.$refs.expViolencia.save();
      }
      if(!isEmpty(this.$refs.riesgoDepresion)){
        await this.$refs.riesgoDepresion.save();
      }
     // await this.$refs.examenes.save();
    },
    async saveExpViolecncia(){
      await this.$refs.expViolencia.save();
    },
    async saveRiesgoDepresion(){
      await this.$refs.riesgoDepresion.save();
    },
    async trimestresUsuarios(){
      const response=await formulariosTrimestreService.trimestresUsuarios(this.idUsuario,this.idHistoria);
      this.usuario_trimestre=response.data;
    }
  },
  watch:{
    idUsuario: async function(value){
      if(value){
        this.trimestresUsuarios();
      }
    }
  },
  created() {
    this.trimestresUsuarios();
  },
  computed:{
    trimestreAvaliable:function(){
      return this.select_trimestres.filter((x)=>!this.usuario_trimestre.includes(x.value));
    }
  }
 
    
}
</script>

