<template>
    <div class=" ">
        <div class="card-header bg-light "><span><i class="fa fa-file-medical"> </i> Embarazo Actual</span> </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Ultima Menstruación: {{embarazo_actual.ultima_menstruacion}}</label>
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Semanas de gestación: {{embarazo_actual.semanas_gestacion}}</label>
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Fecha probable de parto: {{embarazo_actual.fecha_parto}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Presencia de movimientos fetales: {{embarazo_actual.movimientos_fetales}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Sintomas urinarios: {{embarazo_actual.sintomas_urinarios}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Sintomas cervico vaginales: {{embarazo_actual.cervico_vaginales}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Cefalea persistente: {{embarazo_actual.cefalea_persistente}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Edema en cara o miembros: {{embarazo_actual.edema}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Epigastralgia: {{embarazo_actual.epigastralgia}}</label> 
                </div>
                <div class="form-group col-lg-4 col-md-12 col-sm-12">
                    <label class="form-label">Nauseas y vomito: {{embarazo_actual.nauseas}}</label> 
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import embarazoActualService from "../../../../services/embarazoActualService";
import {isEmpty} from "lodash";
import embarazoService from '../../../../services/embarazoService';
export default {
    props: ['idHistoria','idUsuario'],
    data(){
        return {
            id_historia:'',
            embarazo_actual:{
                id:'',
                id_historia:'',
                id_embarazo:'',
                ultima_menstruacion:'',
                semanas_gestacion:'',
                fecha_parto:'',
                movimientos_fetales:'',
                sintomas_urinarios:'',
                cervico_vaginales:'',
                cefalea_persistente:'',
                edema:'',
                epigastralgia:'', 
                nauseas:''
            },
            embarazo:{}
        }
    },
    async created(){
        const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
      this.embarazo=res_embarazo_actual.data;

      if(!isEmpty(res_embarazo_actual.data)){
        const response=await embarazoActualService.showByLast(this.embarazo.id);
        if(!isEmpty(response.data)){
            this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
            this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
            this.embarazo_actual.fecha_parto=response.data.fecha_parto;
            this.embarazo_actual.id_historia=response.data.id_historia;
            this.embarazo_actual.id_embarazo=response.data.id_embarazo;
            this.embarazo_actual.id=response.data.id;
            this.id_historia=response.data.id_historia;
            this.embarazo_actual.movimientos_fetales=response.data.movimientos_fetales;
            this.embarazo_actual.sintomas_urinarios=response.data.sintomas_urinarios;
            this.embarazo_actual.cervico_vaginales=response.data.cervico_vaginales;
            this.embarazo_actual.cefalea_persistente=response.data.cefalea_persistente;
            this.embarazo_actual.edema=response.data.edema;
            this.embarazo_actual.epigastralgia=response.data.epigastralgia;
            this.embarazo_actual.nauseas=response.data.nauseas;

        }
      }
             
        
    },
    watch: {
        idUsuario : async function(){
           const res_embarazo_actual= await embarazoService.showByLast(this.idUsuario);
        this.embarazo=res_embarazo_actual.data;
        if(!isEmpty(res_embarazo_actual.data)){
          const response=await embarazoActualService.showByLast(this.embarazo.id);
          if(!isEmpty(response.data)){
            this.embarazo_actual.ultima_menstruacion=response.data.ultima_menstruacion;
            this.embarazo_actual.semanas_gestacion=response.data.semanas_gestacion;
            this.embarazo_actual.fecha_parto=response.data.fecha_parto;
            this.embarazo_actual.id_historia=response.data.id_historia;
            this.embarazo_actual.id_embarazo=response.data.id_embarazo;
            this.embarazo_actual.id=response.data.id;
            this.id_historia=response.data.id_historia;
            this.embarazo_actual.movimientos_fetales=response.data.movimientos_fetales;
            this.embarazo_actual.sintomas_urinarios=response.data.sintomas_urinarios;
            this.embarazo_actual.cervico_vaginales=response.data.cervico_vaginales;
            this.embarazo_actual.cefalea_persistente=response.data.cefalea_persistente;
            this.embarazo_actual.edema=response.data.edema;
            this.embarazo_actual.epigastralgia=response.data.epigastralgia;
            this.embarazo_actual.nauseas=response.data.nauseas;
          }
        }
        }
    }
}
</script>