<template>
    <main>
        <div class="card shadow-none border-0">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> {{nombre_formulario}}</span> </div>
            <div class="card-body">
                <div v-for="item in formulario" :key="item.id">
                    <h6>{{ item.nombre }}</h6>
                    <table class="table table-striped table-bordered table-sm">
                        <tr>
                            <th>Pregunta</th>
                            <th>Respuesta</th>
                        </tr>
                        <tr v-for="pre in item.preguntas" :key="pre.id">
                            <td>{{ pre.nombre }}</td>
                            <td>
                                {{ pre.respuesta }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import { isEmpty } from 'lodash';
import formulariosService from '../../../../services/formulariosService';
import respuestasFormulariosService from '../../../../services/respuestasFormulariosService';
export default {
    props: ['idHistoria', 'finalizada','codigo','trimestre'],
    data() {
        return {
            formulario:{},
            nombre_formulario:'',
            id_formulario:''
        }
    },
    async created() {
        const response = await formulariosService.showByCode(this.codigo);
        this.id_formulario = response.data.id;
        this.nombre_formulario = response.data.nombre;

        const respuestas_formulario = await respuestasFormulariosService.showByRespuestableId({
            id:this.idHistoria,
                        id_formulario:this.id_formulario,
                        trimestre:this.trimestre!==''?this.trimestre:'',
        });

        this.formulario = response.data.secciones.map(x => {

            return {
                id: x.id,
                nombre: x.nombre,
                preguntas: x.preguntas.map(y => {
                    let respuesta = '';
                    if (!isEmpty(respuestas_formulario.data)) {
                        respuesta = respuestas_formulario.data.items.find(r => r.id_pregunta === y.id)?.valor || ''
                    }
                    return {
                        ...y,
                        respuesta: respuesta,
                    }
                })
            }
        });
    }

}
</script>

